.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  overflow: hidden;
}

.image-ctnr {
  width: 45%;
  position: relative;
  overflow: hidden;
  flex:1
}

.background-im {
  width: 100%;
  height: 100%;
  object-fit: cover;
  justify-content: right;

}

.login-content-new {
  // padding-left: 150px;
  // padding-right: 150px;
  width: 55%;
  display: flex;
  flex-direction: column;
  // gap: 12px;
  // justify-content: space-around;
  align-items: center;

  .login_logo{
    margin: 5vh;
  }
}

.logo-gradient {
  width: 100px;
  height: 16px;
  margin-top: 44px;
  margin-bottom: 150px;
}

.sign-in-text {
  color: #989898;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  margin-bottom: 18px;
}

.elevate-text {
  color: #1E1E1E;
  font-size: 20px;
  font-family: 'Test Founders Grotesk X-Condensed', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1px;
  margin-bottom: 20px;
}

.login-form {
  width: 100%;
  box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
  border-radius: 8px;
  border: 1px #E1E5E4 solid;
  // padding: 40px;
  margin-top: 10px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #E1E5E4;
  margin-bottom: 8px;
}

.input-container {
  padding-left: 32px;
  padding-top: 24px;
}

.input-container label {
  display: block;
  color: #989898;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  margin-bottom: 8px;
}

.input-container input {
  width: 90%;
  padding-bottom: 12px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  color: #2B3940;
  outline: none;
}

.forgot-password-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 12px;
}

.forgot-password {
  color: #989898;
  font-size: 12px;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  letter-spacing: 1.20;
  text-transform: uppercase;
  cursor: pointer;
}

.sign-in-button {
  width: 100%;
  padding: 16px;
  background: #1E1E1E;
  color: white;
  font-size: 16px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 8px;
}

.or-text-container {
  // display: flex;
  // justify-content: center;
  width: 100%;
}

.or-text {
  color: #989898;
  font-size: 16px;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 12px;
}

.social-text {
  font-family: 'Outfit';
  color: '#1E1E1E';
  font-size: 16px;
  font-weight: 600;
}

.social-button {
  width: 100%;
  
  padding: 16px;
  border: 1px solid #E1E5E4;
  border-radius: 8px !important;
  font-family: 'Outfit', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}

.social-icon {
  width: 16px;
  height: 16px;
}

.google-button {
  background: white;
  width: 100%;
}

.apple-button {
  background: white;
  width: 100%;
}

.new-user-text {
  color: #0177D1;
  font-size: 16px;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-bottom: 24px;
}

.footer {
  display: flex;
  gap: 24px;
  margin-top: 0px !important;
  justify-content: left;
  width: 58%;
}

.footer span {
  color: #989898;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

/* Add this CSS to your stylesheet */
@media screen and (min-width: 1600px) {

//   .login-content-new {
// height: 100vh;
//   }

  .sign-in-text{
    margin-bottom: 32px;
  }

  .elevate-text{
    font-size: 48px;
    font-weight: 600;
    line-height: 1px;
     margin-bottom: 40px; 

  }

//   .login-form{
//  margin-top: 1px;
//   }


//   .forgot-password-container{
//     margin-top: 33px;
//   }

//   .sign-in-button{
//     margin-top: 20px;
//   }

//   .or-text{
//     margin-top: 20px;
//   }

//   .social-button {
//     margin-bottom: 20px;
//   }

//   .new-user-text{
//     margin-bottom: 99px;
//   }

  .footer span {
    margin-top: 20px;
  }
}
