.contact-form-container {
    width: 100%;
    background: white;
    box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
    border: 1px solid #E1E5E4;
    border-radius: 8px;
    // padding: 20px;
    margin-bottom: 20px;
}

.contact-input-group {
    // margin-bottom: 20px;
    padding-left: 32px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
}

.contact-input-group input {
    width: 90%;
    padding-bottom: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    color: #2B3940;
    outline: none;
}

.contact-input-group textarea {
    width: 90%;
    padding-bottom: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    color: #2B3940;
    outline: none;
}

.contact-input-group textarea::placeholder {
    color: #B9B9B9;
    font-size: 14px;
    font-family: 'Outfit';font-weight: '500';line-height: 14px;word-wrap: 'break-word'
}
.label {
    color: #989898;
    font-size: 14px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 10px;
}

.input-value {
    color: #1E1E1E;
    font-size: 18px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    line-height: 18px;
}

.contact-phone-input {
    display: flex;
    align-items: center;
}

.country-code, .phone-number {
    color: #1E1E1E;
    font-size: 18px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    line-height: 18px;
    padding-bottom: 12px;
    padding-right: 10px;
}

@media (max-width: 768px) {
.contact-form-container {
    margin-top: 106% !important;
}    
.map{
    position: absolute;
    top: 69%;
    left: 0%;

    iframe{
    height: 326px !important;
    padding-left: 3% !important;
    padding-right: 34% !important;
    }
}
}