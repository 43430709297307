.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  width: 700px;
  padding: 40px;
  background: white;
  box-shadow: 0px 6px 7px rgba(31, 37, 57, 0.04);
  border: 1px solid rgba(33, 39, 50, 0.04);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: #1E1E1E;
    font-size: 32px;
    font-family: 'Test Founders Grotesk X-Condensed', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 48px;
    word-wrap: break-word;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
}

.popup-options {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .option {
    position: relative;
    padding: 24px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    gap: 24px;
    cursor: pointer;
    transition: border-color 0.3s ease;

    &.selected {
      border-color: #0076D0;
    }

    .option-icon {
      width: 48px;
      height: 48px;
      position: relative;

      &.ai-icon::before {
        content: '';
        width: 30.74px;
        height: 41px;
        background: linear-gradient(97deg, #0076D0 35%, #0EFED4 100%);
        position: absolute;
        top: 3.5px;
        left: 8.63px;
      }

      &.scratch-icon::before {
        content: '';
        width: 41.84px;
        height: 48px;
        background: #010205;
        border: 0.5px solid #171717;
        position: absolute;
        top: 0.02px;
        left: 0.35px;
      }
    }

    .option-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;

      h3 {
        color: #10191F;
        font-size: 18px;
        font-family: 'Outfit', sans-serif;
        font-weight: 600;
      }

      p {
        color: #5E5E5E;
        font-size: 14px;
        font-family: 'Outfit', sans-serif;
        font-weight: 400;
        opacity: 0.6;
      }
    }

    .ribbon {
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      width: 75px;
      height: 75px;
      text-align: right;

      span {
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        transform: rotate(45deg);
        width: 100px;
        display: block;
        background: red;
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 19px;
        right: -21px;
      }
    }

    &.scratch .ribbon {
      display: none;
    }
  }
}
