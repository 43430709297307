.pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 1rem 3rem 1rem;

    .tabs {
        position: relative;
        display: flex;
        background-color: #e5e7eb;
        border-radius: 9999px;
        padding: 0.1rem 0.5rem;
        gap: 1.1rem;
    
        button {
          flex: 1;
          padding: 0.5rem;
          background: none;
          border: none;
          cursor: pointer;
          color: #374151;
          font-weight: bold;
          transition: color 0.3s ease;
          z-index: 1;
          outline: none;
    
          &.active {
            color: #fff;
            background: none;
            border: none;
          }
        }
    
        .slider {
          position: absolute;
          top: 0;
          bottom: 0;
          background-color: #000;
          border-radius: 9999px;
          transition: transform 0.3s ease;
    
          &.monthly {
            width: 30%;
            transform: translateX(0%);
            padding-left: 0px;
          }
    
          &.quarterly {
            width: 33%;
            transform: translateX(100%);
          }
    
          &.annual {
            width: 33%;
            transform: translateX(200%);
          }
        }
      }
  
    h1 {
      font-size: 2rem;
      font-weight: bold;
      text-align: left;
      margin-bottom: 1rem;
    }
  
    p {
      color: #6b7280; // TailwindCSS 'text-gray-500' equivalent
      text-align: left;
      margin-bottom: 2rem;
    }
  
    .billing-toggle {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;
  
      button {
        padding: 0.5rem 1rem;
        border-radius: 9999px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &.active {
          background-color: #000;
          color: #fff;
        }
  
        &:not(.active) {
          background-color: #e5e7eb;
          color: #374151;
        }
      }
    }
  
    .pricing-cards {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.5rem;
      max-width: 1200px;
      width: 100%;
  
      @media(min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
  
      .pricing-card {
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        padding: 1.5rem;
        text-align: left;
        transition: transform 0.3s ease;
  
        &:hover {
          transform: scale(1.05);
        }
  
        h2 {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 1rem;
        }
  
        p {
          color: #6b7280;
          margin-bottom: 1rem;
        }
  
        .price {
          font-size: 2.5rem;
          font-weight: bold;
          margin-bottom: 1rem;
  
          span {
            font-size: 1rem;
          }
        }
  
        button {
          width: 100%;
          background-color: #000;
          color: #fff;
          padding: 0.75rem 1.5rem;
          border-radius: 8px;
          border: none;
          cursor: pointer;
          margin-bottom: 1.5rem;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #374151;
          }
        }
  
        ul {
          list-style: none;
          padding: 0;
          text-align: left;
          color: #6b7280;
  
          li {
            margin: 0.5rem 0;
          }
        }
      }
    }
  }
  