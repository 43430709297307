.overview {
    h1 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .flexRow {
      display: flex;
      flex-direction: row;
    }

    .alignBetween {
      justify-content: space-between;
      align-items: center;
    }

    .NewPortfolioButton {
      padding: 12px;
      background-color: #010205;
      color: #FFFFFF;
      font-weight: 700;
      font-size: 1.1rem;
      border-radius: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  
    p {
      font-size: 18px;
      color: #989898;
      margin-bottom: 20px;
    }
  
    .stats {
      display: flex;
      gap: 20px;
    }
  
    .stat {
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 6px 7px rgba(31.08, 37.65, 57.37, 0.04);
      border: 1px solid #e1e5e4;
      display: flex;
      align-items: center;
  
      .icon {
        width: 20px;
        height: 20px;
        padding: 16px;
      }

      .icon-wrap {
        width: 56px;
        height: 56px;
        margin-right: 10px;
        background: rgba(224.72, 228.97, 227.52, 0.32); border-radius: 8px;
      }
  
      .info {
        span {
          display: block;
          font-size: 14px;
          color: #808b8b;
          margin-bottom: 5px;
        }
  
        strong {
          font-size: 36px;
          font-weight: bold;
        }
      }
    }
  }
  