.header {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    // background: white;
    
    margin-bottom: 20px;
  
    .search-bar {
      input {
        width: 296px;
        padding: 10px;
        border: none;
        border-radius: 8px;
        background: white;
        box-shadow: 0px 11px 23px rgba(224.72, 228.97, 227.52, 0.56); border-bottom: 1px #E1E5E4 solid
      }
    }
  
    .user-info {
      display: flex;
      align-items: center;
  
      .notification {
        position: relative;
        width: 40px;
        height: 40px;
        background: white;
        border-radius: 8;
        box-shadow: 0px 2px 11px #e1e5e4;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
  
        .icon {
          width: 20px;
          height: 20px;
        //   background: black;
        }
  
        .count {
          position: absolute;
          top: -5px;
          right: -5px;
          background: black;
          color: white;
          font-size: 12px;
          border-radius: 3;
          padding-top: 2px;
          padding-left: 6px;
          padding-bottom: 2px;
          padding-right: 6px;
        }
      }
  
      .user-details {
        display: flex;
        align-items: center;
  
        .avatar {
          width: 36px;
          height: 36px;
          background: #fcd1b9;
          color: #612c0e;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          font-weight: bold;
          margin-right: 10px;
        }
  
        .details {
          display: flex;
          flex-direction: column;
  
          .name {
            font-weight: bold;
          }
  
          .role {
            font-size: 12px;
            color: #989898;
          }
        }
      }
    }
  }
  

