.backgroundImg {
  background: url("../../assets/images/aboutUsBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.mid .btn {
  padding: 6px 4px 6px 32px;
  // background: black;
  border-radius: 70px;
  border: 1px solid black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #00000080;
  gap: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background 0.3s ease, border 0.3s ease;

  &:hover {
    background: linear-gradient(97deg, #0076d0 35%, #0efed4 100%);
    border: 1px solid #0076d0;

    .arrow {
      animation: moveArrowOut 2s forwards;
    }

    .new-arrow {
      animation: moveArrowIn 2s forwards;
    }
  }
}

.arrow-container {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 2px solid #000000;
  position: relative;
}

.arrow {
  width: 24px;
  height: 24px;
  transition: transform 1s ease, opacity 1s ease;
}

.new-arrow {
  position: absolute;
  width: 24px;
  height: 24px;
  opacity: 0;
}

@keyframes moveArrowOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(30px);
    opacity: 0;
  }
}

@keyframes moveArrowIn {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Reverse animations when hover is removed */
.mid .btn:not(:hover) .arrow {
  animation: moveArrowBackIn 2s forwards;
}

.mid .btn:not(:hover) .new-arrow {
  animation: moveArrowBackOut 2s forwards;
}

@keyframes moveArrowBackIn {
  0% {
    transform: translateX(30px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveArrowBackOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-10px);
    opacity: 0;
  }
}

.mission-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  height: 900px;
  justify-content: center;
  // background-color: #1a1a1a;
  background-image: url("../../assets/images/aboutSection.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 11%;
    height: 20%;
    border-radius: 50% 0% 0% 50%;
    background: linear-gradient(
      126.51deg,
      #90a8fc 18.9%,
      rgba(165, 237, 253, 0) 112.86%
    );
    z-index: 0;
    pointer-events: none;
    opacity: 0.6;
    filter: blur(20px);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 11%;
    height: 20%;
    border-radius: 0 50% 50% 0;
    background: linear-gradient(
      126.51deg,
      #90a8fc 18.9%,
      rgba(165, 237, 253, 0) 112.86%
    );
    z-index: 0;
    pointer-events: none;
    opacity: 0.6;
    filter: blur(20px);
  }

  .mission-intro {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    .mission-circles-background {
      position: absolute;
      left: 50%;

      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -1;

      .mission-circle {
        position: absolute;
        border-radius: 50%;
        border: 2px solid #292929;

        &.large {
          width: 730px;
          height: 730px;
        }

        &.medium {
          width: 594px;
          height: 594px;
        }

        &.small {
          width: 452px;
          height: 452px;
        }

        &.smallest {
          width: 312px;
          height: 312px;
        }
      }
    }

    .mission-tag-container {
      // padding-left: 16px;
      // padding-right: 16px;
      // padding-top: 10px;
      // padding-bottom: 10px;
      border-radius: 16px;
      overflow: hidden;
      border: 1px rgba(0, 118, 208, 0.75) solid;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
      border: 1px rgba(7, 186, 210, 0.75) solid;

      // borde: linear-gradient(92.96deg, rgba(0, 118, 208, 0.75) 37.17%, rgba(14, 254, 212, 0.75) 106.2%);

      .mission-tag {
        color: #ffffff;
        // font-size: 12px;
        font-family: "Outfit", sans-serif;
        // font-weight: 400;
        // text-transform: uppercase;
        // line-height: 12px;
        // word-wrap: break-word;
      }
    }

    .mission-title {
      // font-size: 2.5rem;
      // text-transform: uppercase;
      color: #ffffff;
      // text-align: center;
      margin-bottom: 20px;
    }
    .mission-description {
      // font-size: 1.1rem;
      // text-align: center;
      color: #b9b9b9;
      margin-bottom: 20px;
    }
  }
}

.values-section {
  display: flex;
  // justify-content: space-between; 
  // align-items: center;
  // padding: 100px;
  position: relative;
  gap: 10%;

  .circles-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 500px; 
    height: 350px;
    z-index: -1;
    overflow: hidden;
  }

  .values-intro {
    // max-width: 40%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    position: relative;
    z-index: 1;

    .tag-container {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 16px;
      overflow: hidden;
      border: 1px rgba(0, 118, 208, 0.75) solid;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
      .tag {
        // color: white;
        font-size: 12px;
        font-family: "Outfit", sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 12px;
        word-wrap: break-word;
      }
    }

    .title {
      //   font-size: 2.5rem;
      color: #1e1e1e;
      margin-bottom: 20px;
    }
  }

  .values-list {
    display: flex;
    flex-direction: column;
    gap: 64px;
    // max-width: 40%;

    .waveImage{
      position: absolute;
      top: 228px !important;
      left: -76px !important;
    }

    .values-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      // margin-bottom: 20px;

      .circle-container {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        padding: 34px;
        border: 3px solid #B0D5F180;
        
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      img {
        max-width: none;
      }

      .icon {
        // width: 30px;
        // object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }

      .values-content {
        .values-title {
          font-size: 1.5rem;
          margin-bottom: 5px;
        }
        .values-description {
          font-size: 1rem;
        }
      }
      
      span {
        fontsize: 1.5rem !important;
      }
    }
  }
}

.about-fundRaise {
  // background: linear-gradient(
  //   150.51deg,
  //   #87e2ff 20.9%,
  //   #90a8fc 200%,
  //   #ffffff 0%
  // );
  background-image: url("../../assets/images/accessportfolio.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  box-shadow: 517.0028076171875px 517.0028076171875px 517.0028076171875px;
}

// .companies-logos {
//   position: absolute;
//   top: 96%;
//   overflow: hidden;
//   white-space: nowrap;

//   .logo-scrolls {
//     display: inline-block;
//     animation: scrollLeft 15s linear infinite;

//     img {
//       display: inline-block;
//       margin-right: 20px;
//     }
//   }
// }

// @keyframes scrollLeft {
//   from {
//     transform: translateX(100%);
//   }
//   to {
//     transform: translateX(-100%);
//   }
// }

@media (max-width: 768px) {
  .about-container{
    background: url("../../assets/images/aboutPattern.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;

  .backgroundImg {
    height: 100vh;
    background: url("../../assets/images/aboutUsBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .content {
    }

    .values-section {
      max-width: 100% !important;
      display: flex;
      flex-direction: column;
   
      gap: 64px !important;
      justify-content: space-between !important; 
      align-items: start !important; 
   
      .values-intro {
        padding: 0% 5%;
        img{
          display: none
        }
        // .waveImage{
        //   display: none;
        //   width: 100% !important;
        //   position: absolute;
        //   top: 135% !important;
        //   left: 10% !important;
        //   height: 100vh !important;
        // }
      
      }
      .values-list{
        display: flex;
        flex-direction: column;
        gap: 64px !important;
        margin-top: 20% !important;
        margin-bottom: 1rem !important;
      }

    
      h1 {
        fontsize: 1.5rem;
      }
    }
  }
}
}
