.forgot-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding-top: 60px;

    .title-pass {
        color: #000000;
        font-size: 2rem;
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
    }
    .forgot-pass-input {
        padding: 24px;
        outline: none;
        border-radius: 4px;
        width: calc(100% - 48px);
        background-color: #FFFFFF;
        border: 1px solid #E8E8E8;
        color: #000000;
        font-weight: 600;
        font-size: 1rem;
        box-shadow: 0px 24px 54px 0px rgba(24, 24, 24, 0.0705882353);

        
    }
    .forgot-pass-input::placeholder {
        color: #B9B9B9;
        font-weight: 500;
        font-size: 1rem;
    }

    .text-style {
        color: #989898;
        font-size: 16px;
        font-weight: 400;
    }

    .arrange {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 28%;
    }

    .continue-button {
        width: 100%;
        max-width: 484px;
        padding: 16px;
        background: #1E1E1E;
        color: white;
        font-size: 16px;
        font-family: 'Outfit', sans-serif;
        font-weight: 700;
        line-height: 16px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
    }
}