.hero-section {
  position: relative;
  text-align: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 800px;
  // background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  // background: url("../../assets/images/aboutBackImg.png");
  background: url("../../assets/images/LandingPageBgNoise.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .gradient-circles {
    // width: 50%;
    // height: 100%;
    position: absolute;
    // top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    // right: 50%;
    // transform: rotate(75.69deg);
    align-items: center;
    transform-origin: 0 0;
    opacity: 0.76;
    z-index: 0;

    .circle {
      width: 288.84px;
      height: 306.35px;
      position: absolute;
      transform: rotate(106deg);
      transform-origin: 0 0;
      background: linear-gradient(
        127deg,
        #a1b7f1 0%,
        rgba(24.56, 107.03, 183.16, 0) 100%
      );
      box-shadow: 227.87px 227.87px 227.87px rgba(0, 0, 0, 0.1);
      border-radius: 9999px;
      filter: blur(120.87px);

      &:nth-child(1) {
        left: 633.58px;
        top: 345.79px;
      }
      &:nth-child(2) {
        left: 609.28px;
        top: 485.89px;
      }
      &:nth-child(3) {
        left: 605.11px;
        top: 379.47px;
      }
      &:nth-child(4) {
        left: 580.81px;
        top: 519.57px;
      }
      &:nth-child(5) {
        left: 572.44px;
        top: 379.41px;
      }
      &:nth-child(6) {
        left: 548.14px;
        top: 519.51px;
      }
      &:nth-child(7) {
        left: 433.64px;
        top: 279.57px;
      }
      &:nth-child(8) {
        left: 409.34px;
        top: 419.68px;
      }
      &:nth-child(9) {
        left: 442.77px;
        top: 289.32px;
      }
      &:nth-child(10) {
        left: 418.47px;
        top: 429.42px;
      }
      &:nth-child(11) {
        left: 430.67px;
        top: 234.62px;
      }
      &:nth-child(12) {
        left: 406.38px;
        top: 374.72px;
      }
      &:nth-child(13) {
        left: 540.75px;
        top: 109.12px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(14) {
        left: 516.45px;
        top: 249.22px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(15) {
        left: 605.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(16) {
        left: 205.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(17) {
        left: 215.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(18) {
        left: 225.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(19) {
        left: 235.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(20) {
        left: 245.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(21) {
        left: 255.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(22) {
        left: 265.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(23) {
        left: 275.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(24) {
        left: 285.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }

  .content {
    z-index: 2;
    position: relative;
    top: 20%;

    text-align: center;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    .border-gradient {
      background: linear-gradient(
        92.96deg,
        rgba(0, 118, 208, 0.75) 37.17%,
        rgba(14, 254, 212, 0.75) 106.2%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .trusted-banner {
      background-color: #e0f7fa;
      display: inline-block;
      padding: 5px 15px;
      border-radius: 5px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .gradient-border {
      border: 1px solid;
      border-image-source: linear-gradient(
        92.96deg,
        rgba(0, 118, 208, 0.75) 37.17%,
        rgba(14, 254, 212, 0.75) 106.2%
      );
      border-image-slice: 1;
    }

    h1 {
      font-size: 3rem;
      margin: 20px 0;
      font-weight: bold;
      line-height: 1.2;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 30px;
    }

    .get-started-btn {
      padding: 15px 30px;
      background-color: black;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 1.2rem;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #333;
      }
    }
  }

  .side-images {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .pyramid-img {
      position: absolute;
      left: 0;
      top: 20%;
      // width: 20%;
    }

    .star-img {
      z-index: 3;
      position: absolute;
      right: 0;
      top: 50%;
      // width: 10%;
    }

    .vector-img {
      z-index: 2;
      position: absolute;
      right: -16%;
      bottom: 0;
      width: 47%;
      height: 5%;
    }
  }

  .company-logos {
    position: absolute;
    bottom: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .logos-scroll {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    animation: scroll 15s linear infinite; 
  }
  
  .logoImg {
    height: 30px;
    margin: 0 30px;
  }
  
  .logos-scroll::before,
  .logos-scroll::after {
    content: '';
    display: inline-block;
    width: 100%;
    background: url('../../assets/images/icons/Gaskonlogo.svg') center no-repeat;
    background-size: contain;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  
}

.mid .btn {
  padding: 6px 4px 6px 32px;
  background-color: black !important;
  border-radius: 70px;
  border: 1px solid black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background 0.3s ease, border 0.3s ease;

  &:hover {
    background: linear-gradient(97deg, #0076d0 35%, #0efed4 100%);
    border: 1px solid #0076d0;

    .arrow {
      animation: moveArrowOut 2s forwards;
    }

    .new-arrow {
      animation: moveArrowIn 2s forwards;
    }
  }
}

.arrow-container {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 2px solid #000000;
  position: relative;
}

.arrow {
  width: 24px;
  height: 24px;
  transition: transform 1s ease, opacity 1s ease;
}

.new-arrow {
  position: absolute;
  width: 24px;
  height: 24px;
  opacity: 0;
}

@keyframes moveArrowOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(30px);
    opacity: 0;
  }
}

@keyframes moveArrowIn {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Reverse animations when hover is removed */
.mid .btn:not(:hover) .arrow {
  animation: moveArrowBackIn 2s forwards;
}

.mid .btn:not(:hover) .new-arrow {
  animation: moveArrowBackOut 2s forwards;
}

@keyframes moveArrowBackIn {
  0% {
    transform: translateX(30px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveArrowBackOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-10px);
    opacity: 0;
  }
}

.mid .btn-text {
  color: white;
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  line-height: 28.8px;
}

@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}

@media (max-width: 768px) {
 
  .hero-section {
    height: 100vh;
    background-position: center ;

    .logoImg{
      height: 22px !important;
    }

    .logos-scroll {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      animation: scroll 5s linear infinite !important;
    }

    .content{
    }
    .side-images{
      .pyramid-img {
        position: absolute;
        left: 0;
        top: 5%;
        width: 22vw;
      }
  
      .star-img {
        z-index: 3;
        position: absolute;
        right: 0;
        top: 65%;
        width: 40vw;
      }
      .vector-img {
        z-index: 2;
        position: absolute;
        right: -222px;
        bottom: 0;
        width: 100%;
        height: 4% !important
      }
    }
  }
}
