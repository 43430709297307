.testimonials-section {
    padding: 100px;
    background-color: #f8f8f8;
    position: relative;

    .testimonials-header {
        // text-align: center;
        margin-bottom: 50px;

        // h1 {
        //     // margin: 20px 0;
        //     // font-size: 48px;
        //     color: #1e1e1e;
        //     font-family: 'Test Founders Grotesk X-Condensed', sans-serif;
        //     font-weight: 600;
        //     text-transform: uppercase;
        // }

        p {
            font-size: 16px;
            color: #4b4b4b;
            font-family: 'Outfit', sans-serif;
            font-weight: 400;
            // max-width: 600px;
            margin: 0 auto;
        }
    }

    .testimonials-content {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
        justify-content: center;
        gap: 30px;
        position: relative;

        .quote-left {
            position: absolute;
            top: -20px;
            left: 20px;
            // font-size: 72px;
            // background: linear-gradient(92.96deg, rgba(0, 118, 208, 1) 37.17%, rgba(14, 254, 212, 1) 106.2%);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // font-family: 'Test Founders Grotesk X-Condensed', sans-serif;
            // font-weight: 600;
            // transform: rotate(15deg); 
            z-index: 1;
        }

        .quote-right {
            position: absolute;
            bottom: -40px;
            right: 25px;
            // font-size: 72px;
            // background: linear-gradient(92.96deg, rgba(0, 118, 208, 1) 37.17%, rgba(14, 254, 212, 1) 106.2%);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // font-family: 'Test Founders Grotesk X-Condensed', sans-serif;
            // font-weight: 600;
            // display: inline-block; 
            // transform: rotate(15deg); 
            z-index: 1;
        }

        .testimonial-card {
            justify-self: center;
            background-color: white;
            padding: 40px;
            border-radius: 40px;
            border: 1px solid #e1e5e4;
            position: relative;
            width: 100%;
            max-width: 558px;
            z-index: 2;
            transition: opacity 0.5s ease, transform 0.5s ease; // Smooth transitions for opacity and transform

            // Default state for no movement
            &.fade-out {
              opacity: 0;
              transform: translateY(-20px); // Move up slightly when fading out
            }
            
            &.fade-in {
              opacity: 1;
              transform: translateY(0); // Back to original position when fading in
            }
        
            &:hover {
              transform: scale(1.05); // Subtle hover effect
            }

            .name {
                margin-bottom: 12px;
                font-size: 24px;
                color: #1e1e1e;
                font-family: 'Test Founders Grotesk X-Condensed', sans-serif;
                font-weight: 600;
                // line-height: 110px;
                text-transform: uppercase;
            }

            .title {
                text-align: left;
                margin-bottom: 25px;
                font-size: 16px;
                line-height: 28px;
                color: #4b4b4b;
                font-family: 'Outfit', sans-serif;
                font-weight: 400;
            }

            .review {
                font-size: 20px;
                color: #686868;
                font-family: 'Outfit', sans-serif;
                font-weight: 400;
                line-height: 1.4;
            }
        }

        .refresh-icon-container {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            height: 80px;
            // background-color: #f8f8f8;
            border-radius: 50%;
            // border: 1px solid black;
            background: #E9E9E9;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;

            .refresh-icon {
                width: 23px;
                height: 23px;
            }
        }
    }
}


@media (max-width: 768px) {
    .testimonials-section{
        padding-top: 16% !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
        margin-left: 0%;

        .testimonials-header{
            padding: 0% 3%;
        }
        .testimonials-content{
            display: grid;
            grid-template-columns: repeat(1, auto);
            /* grid-template-rows: repeat(2, auto); */
            justify-content: center;
            gap: 30px;
            position: relative;
            padding: 4% 3%;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}