.portfolio-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  font-family: "Outfit", sans-serif;

  .access-portfolio {
    padding-left: 100px;
    padding-bottom: 100px;
    padding-right: 100px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: white;
    z-index: 0;
    flex-direction: row;
    margin-top: 10%;

    h1 {
      font-size: 60px;
      font-weight: 600;
      line-height: 1.2;
      color: #1e1e1e;
    }

    .expand-image {
      display: inline-block;
      overflow: hidden;
      position: relative;
      height: 100px;
      width: 190px;
    }

    .expand-image img {
      width: auto;
      height: auto;
      transform: translateX(-95%);

      /* Animation properties */
      animation-name: revealImage;
      animation-duration: 6s; /* Total duration for forward and backward */
      animation-fill-mode: forwards; /* Keep the final state */
      animation-timing-function: ease-in-out;
      animation-delay: 2s; /* Delay before starting */
      animation-iteration-count: infinite; /* Repeat indefinitely */
    }

    .moving-text {
      font-size: 60px;
      font-weight: bold;
      line-height: 1.2;
      position: relative;
      left: -180px;

      /* Animation properties */
      animation-name: moveText;
      animation-duration: 6s; /* Total duration for forward and backward */
      animation-fill-mode: forwards; /* Keep the final state */
      animation-timing-function: ease-in-out;
      animation-delay: 2s; /* Delay before starting */
      animation-iteration-count: infinite; /* Repeat indefinitely */
    }

    @keyframes revealImage {
      0% {
        transform: translateX(-95%);
      }

      50% {
        transform: translateX(0); /* Move to fully visible */
      }

      100% {
        transform: translateX(-95%); /* Return to original position */
      }
    }

    @keyframes moveText {
      0% {
        left: -180px;
        opacity: 1;
      }

      50% {
        left: 0;
        opacity: 1;
      }

      100% {
        left: -180px;
        opacity: 1;
      }
    }

    p {
      font-size: 16px;
      color: #676767;
    }

    .form-container {
      width: 484px;
      background: white;
      box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
      border: 1px solid #e1e5e4;
      border-radius: 8px;
      padding: 0px !important;
      margin-bottom: 20px;
    }

    .pending-box {
      width: 38%;
      box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
      border-radius: 8px;
      border: 1px #e1e5e4 solid;
      margin-top: 10px;
    }

    .email-verification {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      // gap: 10px;

      .input-section {
        padding-left: 32px;
        padding-top: 24px;
        border-bottom: 1px solid #e1e5e4;
      }

      .input-section label {
        display: block;
        color: #989898;
        font-size: 14px;
        font-family: "Outfit", sans-serif;
        font-weight: 500;
        margin-bottom: 8px;
      }
      // .errorPhone {
      //   width: 150px !important;
      // }
      .input-wrapper {
        display: flex;
        align-items: flex-start;
        // border-bottom: 1px solid #e1e5e4;

        .custom-phone-input {
          width: 66%;
        }
      }

      .input-section input {
        width: 66% !important;
        padding-bottom: 12px;
        // border-bottom: 1px solid #e1e5e4;
        border-radius: 4px;
        font-size: 16px;
        font-family: "Outfit", sans-serif;
        font-weight: 500;
        color: #2b3940;
        outline: none;
      }

      input {
        padding: 0px;
        border: none;
        // border-bottom: 1px solid #e1e5e4;
        font-size: 24px;
        width: 100%;
        font-family: "Outfit";
        font-weight: 400;
      }

      .verify-now {
        padding: 5px;
        background: #15171a;
        color: white;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-size: 16px;
        font-family: "Outfit";
        font-weight: 400;
        transition: all 0.3s ease;
        width: 190px;

        &:hover {
          background: linear-gradient(97deg, #0076d0 35%, #0efed4 100%);
          color: #fff;
        }
      }
    }
  }

  .ready-to-team-up {
    text-align: center;
    padding: 100px 0;
    background: linear-gradient(180deg, #37c8db 0%, #59e7f9 100%);
    color: white;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    height: 1000px;

    .gradient-shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: radial-gradient(
        circle at var(--mouse-x) var(--mouse-y),
        //   rgba(13, 237, 212, 0.47), rgba(13, 237, 212, 0),
          //   rgba(144, 168, 252, 1), rgba(165, 237, 253, 0),
          rgba(161, 183, 241, 0.5),
        rgba(25, 107, 183, 0),
        rgba(135, 226, 255, 0.5),
        rgba(255, 255, 255, 0)
      );
      transition: background 0.1s;
    }

    h1 {
      font-size: 80px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .lets-talk {
      padding: 5px;
      background: white;
      color: #15171a;
      border: none;
      border-radius: 50px;
      cursor: pointer;
      font-size: 20px;
      font-family: "Outfit";
      font-weight: 400;
      width: 190px;

      &:hover {
        background: #15171a;
        color: white;
      }
    }

    p {
      font-size: 16px;
      margin-top: 10px;
      font-family: "Outfit";
      font-weight: 400;
      color: black;
    }
  }
}

@media (max-width: 768px) {
  .portfolio-container {
    .access-portfolio {
      display: block;
      padding: 0% 5%;
      // justify-content: space-between;
      margin-top: 36%;
      margin-bottom: 10%;

      p {
        width: auto !important;
      }
    }

    .form-container {
      width: 100% !important;
      background: white;
      box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
      border: 1px solid #e1e5e4;
      border-radius: 8px;
      margin-bottom: 20px;
    }

    .custom-phone-input {
      width: 89% !important;
    }

    .pending-box {
      width: 100% !important;
      box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
      border-radius: 8px;
      border: 1px #e1e5e4 solid;
      margin-top: 30px !important;
    }

    .errorPhone {
      // width: 150px !important;
      margin-right: 8% !important;
    }
    .team-upperPart1 {
      height: 33px !important;
      margin: 0% 10% !important;
    }

    .team-upperPart2 {
      width: 100% !important;
      margin: 0 !important;
    }

    .ready-to-team-up {
      // background: linear-gradient(180deg, #37c8db 0%, #59e7f9 100%);
      h1 {
        font-size: 63px !important;
      }
    }
  }
}
