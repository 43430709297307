.commitments-section {
  background-color: #f8f8f8;
  position: relative;
  overflow: hidden;
  .commitment-container {
    padding: 100px;

    .header {
      // text-align: center;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: start;
      z-index: 1;

      h1 {
        font-size: 48px;
        color: #1e1e1e;
        font-family: "Test Founders Grotesk X-Condensed", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        color: #4b4b4b;
        font-family: "Outfit", sans-serif;
        font-weight: 400;
        max-width: 600px;
        // margin: 0 auto;
      }
    }
  }
  .gradient-circles {
    position: absolute;
    transform: rotate(75.69deg);
    top: -40%;
    right: -10%;
    transform-origin: 0 0;
    z-index: 0;

    .circle {
      width: 300.84px;
      height: 400.35px;
      position: absolute;
      transform: rotate(180deg);
      transform-origin: 0 0;
      background: linear-gradient(
        127deg,
        #a1b7f1 0%,
        rgba(24.56, 107.03, 183.16, 0) 100%
      );
      // background: linear-gradient(126.51deg, #A1B7F1 18.9%, rgba(25, 107, 183, 0) 112.86%);

      // box-shadow: 227.87px 227.87px 227.87px rgba(0, 0, 0, 0.1);
      border-radius: 9999px;
      filter: blur(160.87px);

      &:nth-child(1) {
        left: 633.58px;
        top: 345.79px;
      }
      &:nth-child(2) {
        left: 609.28px;
        top: 485.89px;
      }
      &:nth-child(3) {
        left: 605.11px;
        top: 379.47px;
      }
      &:nth-child(4) {
        left: 580.81px;
        top: 519.57px;
      }
      &:nth-child(5) {
        left: 572.44px;
        top: 379.41px;
      }
      &:nth-child(6) {
        left: 548.14px;
        top: 519.51px;
      }
      &:nth-child(7) {
        left: 433.64px;
        top: 279.57px;
      }
      &:nth-child(8) {
        left: 409.34px;
        top: 419.68px;
      }
      &:nth-child(9) {
        left: 442.77px;
        top: 289.32px;
      }
      &:nth-child(10) {
        left: 418.47px;
        top: 429.42px;
      }
      &:nth-child(11) {
        left: 430.67px;
        top: 234.62px;
      }
      &:nth-child(12) {
        left: 406.38px;
        top: 374.72px;
      }
      &:nth-child(13) {
        left: 540.75px;
        top: 109.12px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(14) {
        left: 516.45px;
        top: 249.22px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:nth-child(15) {
        left: 605.98px;
        top: 108.83px;
        background: linear-gradient(
          127deg,
          #87e2ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }

  .features-container {
    position: relative;
    overflow-x: auto;
    padding: 10px 0;
    margin-bottom: 50px;

    .features {
      display: flex;
      justify-content: center;
      width: 100%;
      // -ms-overflow-style: none;
      // scrollbar-width: none;

      // .features::-webkit-scrollbar {
      //   display: none;
      // }

      .feature {
        display: flex;
        flex-direction: column;
        align-items: start;
        min-width: 250px;
        padding: 0px 30px;

        .sub-feature {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;

          .icon {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: 20px;

            img {
              max-width: none;
            }
          }

          h3 {
            font-size: 18px;
            color: #1e1e1e;
            font-family: "Outfit", sans-serif;
            font-weight: 600;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
            color: #4b4b4b;
            font-family: "Outfit", sans-serif;
            font-weight: 400;
          }
        }
      }

      .separator {
        width: 1px;
        height: 200px;
        background-color: #e8e8e8;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 16px;
          height: 16px;
          background-color: #e8e8e8;
          border-radius: 50%;
          border: 2px solid #e8e8e8;
        }
      }
    }
  }

  .stats-section {
    background-image: url("../../assets/images/icons/Striped\ Textile\ Photo\ 1.png"); /* Path relative to the public folder */
    background-size: cover; /* Ensure the image covers the entire section */
    background-position: center; /* Center the image */
    background-repeat: no-repeat;

    .stats {
      display: flex;
      justify-content: space-around;
      padding: 100px 80px;
      // background-color: #333;
      color: white;
      text-align: center;

      .stat {
        flex: 1;
        margin: 0 20px;
        // h2 {
        //     font-size: 48px;
        //     font-weight: 600;
        //     margin-bottom: 10px;
        // }

        // p {
        //     font-size: 16px;
        //     max-width: 200px;
        //     margin: 0 auto;
        // }
      }
    }
  }

  .access-portfolio {
    text-align: center;
    background-image: url("../../assets/images/accessportfolio.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // background: linear-gradient(
    //   150.51deg,
    //   #87e2ff 20.9%,
    //   #90a8fc 200%,
    //   #ffffff 0%
    // );

    // background: linear-gradient(126.51deg, rgba(13, 237, 212, 0.468) 18.9%, rgba(13, 237, 212, 0) 112.86%);

    // background: linear-gradient(127deg, #87E2FF 0%, rgba(255, 255, 255, 0) 100vw);
    box-shadow: 517.0028076171875px 517.0028076171875px 517.0028076171875px;
    height: 89px;
    // filter: blur(517px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // padding: 0px 40px;

    .access {
      // font-size: 24px;
      // line-height: 24px;
      color: black;
      text-decoration: none;
      // padding: 10px 20px;
      transition: all 0.3s;

      &:hover {
        // background-color: #0076d0;
        color: #0076d0;
        cursor: pointer;
      }
    }
  }

  // .quarter-circle {
  //     position: absolute;
  //     top: -50px;
  //     right: -50px;
  //     width: 300px;
  //     height: 300px;
  //     opacity: 0.6;

  //     img {
  //         width: 100%;
  //         height: 100%;
  //         transform: rotate(45deg); // Adjust the rotation if needed
  //     }
  // }
}

// .features-container::-webkit-scrollbar {
//   display: none;
// }

@media (max-width: 768px) {
  .commitments-section {
    display: flex;
    flex-direction: column;

    .commitment-container {
      padding: 0 !important;

      .header {
        gap: 3vh;
        padding: 10% 4% 4% 4%;

        .gradient-circles {
          position: absolute;
          transform: rotate(63.69deg);
          top: -8%;
          right: -63%;
          transform-origin: -1 17;
          z-index: 0;
        }

        .z-50 {
          position: absolute;
          top: 25% !important;
          left: 60% !important;
        }

        .custom-arrow {
          width: 106px;
        }
      }

      .features {
        // padding: 10% 4% 0% 11%;
        padding: 14% % 0% 10%;
      }
    }

    .stats {
      flex-direction: column;
      gap: 82px;
    }

    .access-portfolio {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
}
