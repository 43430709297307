.contactus {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f8f8;
  font-family: "Outfit", sans-serif;
}
.ready-to-team-up {
  text-align: center;
  padding: 100px 0;
  background: linear-gradient(180deg, #37c8db 0%, #59e7f9 100%);
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  height: 1000px;

  .gradient-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: radial-gradient(
      circle at var(--mouse-x) var(--mouse-y),
      //   rgba(13, 237, 212, 0.47), rgba(13, 237, 212, 0),
        //   rgba(144, 168, 252, 1), rgba(165, 237, 253, 0),
        rgba(161, 183, 241, 0.5),
      rgba(25, 107, 183, 0),
      rgba(135, 226, 255, 0.5),
      rgba(255, 255, 255, 0)
    );
    transition: background 0.1s;
  }

  h1 {
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .lets-talk {
    padding: 5px;
    background: white;
    color: #15171a;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 20px;
    font-family: "Outfit";
    font-weight: 400;
    width: 190px;

    &:hover {
      background: linear-gradient(97deg, #0076d0 35%, #0efed4 100%);
      border: 1px solid #0076d0;
      z-index: 999;

      .arrow {
        animation: moveArrowOut 2s forwards;
      }

      .new-arrow {
        animation: moveArrowIn 2s forwards;
      }
    }
  }
  .arrow-container {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #000000;
    position: relative;
  }

  .arrow {
    width: 24px;
    height: 24px;
    transition: transform 3s ease;
  }

  .new-arrow {
    position: absolute;
    width: 24px;
    height: 24px;
    opacity: 0;
  }

  @keyframes moveArrowOut {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(30px);
      opacity: 0;
    }
  }

  @keyframes moveArrowIn {
    0% {
      transform: translateX(-10px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* Reverse animations when hover is removed */
  .lets-talk:not(:hover) .arrow {
    animation: moveArrowBackIn 2s forwards;
  }

  .mid .btn:not(:hover) .new-arrow {
    animation: moveArrowBackOut 2s forwards;
  }

  @keyframes moveArrowBackIn {
    0% {
      transform: translateX(30px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes moveArrowBackOut {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(-10px);
      opacity: 0;
    }
  }

  p {
    // font-size: 16px;
    margin-top: 15px;
    font-family: "Outfit";
    // font-weight: 400;
    color: #1e1e1e;
  }
}

@media (max-width: 768px) {
  .contactus{
    padding-top: 16% !important;
    
    .contact_Talk1{
      width: 80%;
      margin: auto !important;
    }

    .contact_Talk2{
      width: 100% !important;
      margin: 0% !important;
    }

    .ready-to-team-up{
      
      h1{
        font-size: 63px;
      }
    }
 } 
}
