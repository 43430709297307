.App {
  text-align: center;
}

body {
  font-family: 'Founders Grotesk', sans-serif;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.react-tel-input {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  position: relative;
  /* please use nested class for custom width */
  /* width: 66% !important; */ 
}

.separator {
  width: 1px;
  height: 200px;
  background-color: #e8e8e8;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background-color: #e8e8e8;
    border-radius: 50%;
    border: 2px solid #e8e8e8;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.contus input, .contus textarea{
  border: none;
  font-size: 1rem;
  font-weight: 700;
  color: #1E1E1E!important;
  padding-left: 0;
}
.contus label {
  font-size: 1rem;
  color: #676767!important;
}

/* @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
@keyframes spinVertical {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
.animate-spin-smooth {
  animation: spin 15s linear infinite;
}
