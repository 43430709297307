.services-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px;
  background-color: #1a1a1a;
  color: #fff;
  // height: 900px;
  position: relative;
  outline: 1px ridge #1a1a1a;

  .circle-bg {
    background-image: url('../../assets/images/circleBg.svg');
    background-position: left center;
    background-repeat: no-repeat;
    // background-size: cover;
  }

  .services-intro {
    max-width: 40%;
    position: relative;
    z-index: 1;

    .circles-background {
      position: absolute;
      // width: 100%;
      // height: 100%;
      top: 50%;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -1;

      .circle {
        position: absolute;
        border-radius: 50%;
        border: 2px solid #292929;

        &.large {
          width: 730px;
          height: 730px;
        }

        &.medium {
          width: 594px;
          height: 594px;
        }

        &.small {
          width: 452px;
          height: 452px;
        }

        &.smallest {
          width: 312px;
          height: 312px;
        }
      }
    }

    .tag-container {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 16px;
      overflow: hidden;
      border: 1px rgba(7, 186, 210, 0.75) solid;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
      .tag {
        color: white;
        font-size: 12px;
        font-family: "Outfit", sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 12px;
        word-wrap: break-word;
      }
    }

    .title {
      font-size: 2.5rem;
      color: #fff;
      margin-bottom: 20px;
    }
    .description {
      font-size: 1.1rem;
      margin-bottom: 30px;
    }
    .lets-talk {
      padding: 5px;
      background: white;
      color: #15171a;
      border: none;
      border-radius: 50px;
      cursor: pointer;
      font-size: 20px;
      font-family: "Outfit";
      font-weight: 400;
      width: 190px;
      gap: 20px;

      &:hover {
        background: linear-gradient(97deg, #0076d0 35%, #0efed4 100%);
        border: 1px solid #0076d0;

        .arrow {
          animation: moveArrowOut 2s forwards;
        }

        .new-arrow {
          animation: moveArrowIn 2s forwards;
        }
      }
    }

    .arrow-container {
      width: 40px;
      height: 40px;
      background-color: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #000000;
      position: relative;
    }

    .arrow {
      width: 24px;
      height: 24px;
      transition: transform 1s ease, opacity 1s ease;
    }

    .new-arrow {
      position: absolute;
      width: 24px;
      height: 24px;
      opacity: 0;
    }

    @keyframes moveArrowOut {
      0% {
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        transform: translateX(30px);
        opacity: 0;
      }
    }

    @keyframes moveArrowIn {
      0% {
        transform: translateX(-10px);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }

    /* Reverse animations when hover is removed */
    .lets-talk:not(:hover) .arrow {
      animation: moveArrowBackIn 2s forwards;
    }

    .mid .btn:not(:hover) .new-arrow {
      animation: moveArrowBackOut 2s forwards;
    }

    @keyframes moveArrowBackIn {
      0% {
        transform: translateX(30px);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @keyframes moveArrowBackOut {
      0% {
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        transform: translateX(-10px);
        opacity: 0;
      }
    }
  }

  .services-list {
    display: flex;
    flex-direction: column;
    gap: 64px;

    .service-item {
      display: flex;
      align-items: center;
      // margin-bottom: 20px;

      .icon {
        width: 96px;
        height: 102px;
        margin-right: 20px;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .market-analysis-icon {
        background-image: url("../../assets/images/icons/MarketAnalysisIcon.svg");
      }

      .strategy-development-icon {
        background-image: url("../../assets/images/icons/StrategyDevelopementIcon.svg");
      }

      .financial-planning-icon {
        background-image: url("../../assets/images/icons/StrategyDevelopementIcon.svg");
      }

      .risk-management-icon {
        background-image: url("../../assets/images/icons/RiskManagementIcon.svg");
      }

      .service-content {
        .service-title {
          font-size: 1.5rem;
          margin-bottom: 5px;
        }
        .service-description {
          font-size: 1rem;
        }
      }
    }
  }
}


@media (max-width: 768px){
  .services-section {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 16% 0px 0px 10%;
    background-image: url("../../assets/images/serviceCircle.png");
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat; 
    // height: 100vh;


    .services-intro{
      max-width: none;
    }

    .services-list{
      padding: 10% 0%;
      gap: 32px;

      .service-item .icon {
        width: 123px;
        height: 56px;
        
      }
    }
  }
}
