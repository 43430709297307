.verify-container {
    // width: 100%;
    height: 100%;
    position: relative;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    
}

.gaskon-logo {
    width: 100px;
    height: 16px;
}

.ev-title {
    color: #1E1E1E;
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
}

.otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
.otp-input-container {
display: flex;
gap: 12px;
margin-bottom: 20px;
}

.button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.continue-button {
    width: 100%;
    max-width: 484px;
    padding: 16px;
    // background: #1E1E1E;
    color: white;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    line-height: 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}


.social-signup {
    width: 100%;
    max-width: 484px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
}

.footer {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.footer-link {
    color: #989898;
    font-size: 14px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    line-height: 14px;
    text-decoration: none;
}

.didnt-receive {
    color: #989898;
    font-size: 18px;
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    text-decoration: none;
    line-height: 16px;
}

.resend-link {
    color: #0177D1;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    text-decoration: underline;
    line-height: 16px;
}

.right-arrow {
    width: 15px;
    height: 15px;
}

.back-button {
    margin-left: 40px;
    margin-top: 0px;
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: row;
    color: 'black';
    font-size: 14;
    font-weight: '400';
    word-wrap: 'break-word';
    cursor: pointer;
}

.business-type-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    max-width: 900px;
  }
  
  .business-type {
    padding: 16px;
    background: white;
    box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
    border-radius: 8px;
    border: 1px solid #E1E5E4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    opacity: 0.70;
    cursor: pointer;
    transition: all 0.3s ease;
  }
 
  .business-type.selected {
    // background: #1E1E1E;
    // color: white;
    // opacity: 1;
    border: 1px #0177D1 solid;
  }

  
  .business-goals-textarea {
    width: 484px;
    height: 80px;
    background: white;
    border: 1px solid transparent;
    padding: 20px;
    font-size: 18px;
    line-height: 18px;
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    color: #989898;
    text-align: left;
    margin-top: 20px;
    box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
    border-radius: 8px;
    border: 1px #E1E5E4 solid;
    transition: border-color 0.3s ease; 
    outline: none;
  }

  .business-name-input {
    width: 484px;
    height: 80px;
    background: white;
    border: 1px solid transparent;
    padding: 0 20px;
    font-size: 18px;
    line-height: 18px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    color: #1E1E1E;
    text-align: left;
    margin-top: 20px;
    box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
    border-radius: 8px;
    border: 1px #E1E5E4 solid;
    transition: border-color 0.3s ease; 
    outline: none;
  }
  
  .business-type-input {
    width: 484px;
    height: 50px;
    background: white;
    border: 1px solid transparent;
    padding: 0 20px;
    font-size: 18px;
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    color: #989898;
    text-align: center;
    margin-top: 20px;
    box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
    border-radius: 8px;
    border: 1px #E1E5E4 solid;
    transition: border-color 0.3s ease;
  }

  .business-type-input:focus {
    border-color: #007bff;
    outline: none;
}