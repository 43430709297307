.recent-document {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 6px 7px rgba(31.08, 37.65, 57.37, 0.04);
    border: 1px solid #e1e5e4;
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
  
      h2 {
        font-size: 20px;
        font-weight: bold;
      }
  
      a {
        color: #686868;
        font-size: 12px;
      }
    }
  
    .document {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .icon {
        width: 40px;
        height: 40px;
        // background: #00733b;
      }
  
      .info {
        flex-grow: 1;
        margin-left: 10px;
  
        strong {
          font-size: 16px;
        }
  
        span {
          display: block;
          color: #a4a8ad;
          font-size: 12px;
        }
      }
  
      .actions {
        display: flex;
        gap: 10px;
  
        .icon-wrap {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid #e1e5e4;          
        }

        .icon {
          padding: 12px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  