.signup-container {
    // width: 484px;
    height: 100%;
    position: relative;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.logo {
    width: 100px;
    height: 16px;
    margin-bottom: 53px;
    margin-top: 25px;
}

.signup-title {
    color: #1E1E1E;
    font-size: 36px;
    font-family: 'Test Founders Grotesk X-Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 48px;
    margin-bottom: 51px;    
    letter-spacing: -1px;
}

.reset-title {
    color: #1E1E1E;
    font-size: 36px;
    font-family: 'Test Founders Grotesk X-Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 48px;    
    margin-bottom: 12px;
    letter-spacing: -1px;
}

.form-container {
    width: 484px;
    background: white;
    box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
    border: 1px solid #E1E5E4;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.input-group {
    // margin-bottom: 20px;
    padding-left: 32px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
}

.input-group input {
    width: 90%;
    padding-bottom: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    color: #2B3940;
    outline: none;
}
.label {
    color: #989898;
    font-size: 14px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 10px;
}

.input-value {
    color: #1E1E1E;
    font-size: 18px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    line-height: 18px;
}

.phone-input {
    display: flex;
    align-items: center;
}

.country-code, .phone-number {
    color: #1E1E1E;
    font-size: 18px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    line-height: 18px;
    padding-bottom: 12px;
    padding-right: 10px;
}

.password-strength {
    width: 484px;
    text-align: left;
    border: 1px solid #E1E5E4;
    font-family: 'Outfit', sans-serif;
    padding: 15px;
    margin-bottom: 20px;
}
.password-strength-ct {
    text-align: left;
    border: 1px solid #E1E5E4;
    font-family: 'Outfit', sans-serif;
    padding: 15px;
    margin-bottom: 20px;
}

.strength-status {
    color: #4EA737;
    font-size: 14px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    line-height: 14px;
    margin-left: 4px;
}

.password-strength ul, .password-strength-ct ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.password-strength li, .password-strength-ct li {
    // color: #989898;
    font-size: 14px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    line-height: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.button-container {
    width: 484px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.continue-button {
    width: 484px;
    max-width: 484px;
    padding: 16px;
    color: white;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    line-height: 16px;
    border-radius: 100px;
    border: none;
    cursor: pointer;
}

.or-text {
    color: #989898;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 20px;
}

.social-signup {
    width: 100%;
    max-width: 484px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
}

.social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 16px;
    border-radius: 100px;
    border: 1px solid #E1E5E4;
    cursor: pointer;
    background: white;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    line-height: 16px;
}

.footer {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.footer-link {
    color: #989898;
    font-size: 14px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    line-height: 14px;
    text-decoration: none;
}

.sign-in-link {
    color: #0177D1;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    text-decoration: underline;
    line-height: 16px;
}

.right-arrow {
    width: 15px;
    height: 15px;
}

.back-button {
    margin-top: 40px;
    margin-left: 40px;
    font-family: 'Outfit', sans-serif;
    display: flex;
    flex-direction: row;
    color: 'black';
    font-size: 14;
    font-weight: '400';
    word-wrap: 'break-word';
    cursor: pointer;
}

.country-code-dropdown {
    width: 55px; /* Adjust width based on your layout */
    margin-bottom: 8px;
    // border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    color: #333;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    border: none;
    transition: border-color 0.3s ease;
}

.country-code-dropdown option {
    padding: 8px 12px;
    background-color: #fff;
    color: #333;
}

.country-code-dropdown::-ms-expand {
    display: none; /* Remove default arrow in IE */
}

.head-desc {
    font-weight: 400;
    font-size: 1rem;
    color: #989898;
    margin-bottom: 24px;
}


@media (max-width: 768px) {
    .signup-container{
       padding: 20px 21px !important; 

       .form-container{
        width: 100% !important;
       }

       .password-strength{
        width: auto !important
       }
    }
}