.energy-refining-solutions {
  background: url(../../../assets/images/icons/NorwoodBg.png) no-repeat center
    top fixed;
  background-size: cover;
}

.container {
  // position: relative;
  width: 100%;
  // height: 100vh;
  // overflow: hidden;
}

.background-image .bg-image {
  width: 100%;
  height: 150vh;
  object-fit: cover;
}

.background-image .bg-image-ensure {
  width: 100%;
  object-fit: cover;
  border-radius: 24;
}

.background-image-gradient .bg-image {
  width: 100%;
  height: 150vh;
  object-fit: cover;
}

.background-image-gradient {
  width: 100%;
  background: linear-gradient(
    89.66deg,
    rgba(201, 214, 255, 0.32) -8.18%,
    rgba(226, 226, 226, 0.32) 108.89%
  );
}

.main-title h1 {
  // font-size: 6rem;
  font-weight: 900;
  margin: 0;
  color: #fff;
}

.net-worth {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.net-worth h2 {
  font-size: 3rem;
  margin: 0;
  color: #fff;
}

.net-worth p {
  font-size: 1rem;
  margin: 0;
  color: #fff;
}

.expected {
  background-color: #fff;
  color: #1e1e1e !important;
  border-radius: 4px;
  padding: 8px;
  width: fit-content;
}

.net-worth button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
}

.mission-vision {
  // width: 420px;
  background-color: #1e1e1e;
  padding: 24px 32px;
  // border-radius: 16px;
}
.mission-vision span {
  font-size: 3rem;
  font-weight: 800;
  color: #fff;
}

.mission-vision p {
  color: #b9b9b9;
  font-size: 1.3rem;
}

.energyoil-container .textcontainer .h2 {
  color: #2a2a2a;
  font-size: 1rem;
}

.energyoil-container .textcontainer .p {
  color: #676767;
  font-size: 1rem;
}

.products-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 85%;
}

.products-title h1 {
  color: #ffffff;
  font-weight: 600;
  font-size: 3rem;
}

.product-note {
  width: 80%;
}

.product-note p {
  color: #ffffff;
  font-weight: 400;
  font-size: 1rem;
}

.scroll-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  overflow-x: auto;
  scrollbar-width: none;
  width: 85%;
}

.scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.bluecard {
  width: 550px;
  min-height: 300px;
  background: linear-gradient(103.99deg, #0294ff 55.01%, #0efed4 157.18%);
  padding: 56px 40px;
  flex-shrink: 0;
  border-radius: 24px;
}

.bluecard span {
  font-size: 5rem;
  font-weight: 900;
  color: #ffffff;
  white-space: pre-wrap;
}

.bluecard p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #ebebeb;
}

.keyinsights span {
  color: #1e1e1e;
  font-size: 6rem;
  font-weight: 900;
}
.model-container {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 8px 124px 0px #1e1e1e0a;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.model-container-row {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 8px 124px 0px #1e1e1e0a;
  // padding: 24px;
  display: flex;
  flex-direction: row;
}

.model-header {
  color: #1e1e1e;
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
}

.model-header-gradient {
  color: #0294ff;
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
}

.model-description {
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
}

.blue {
  background: linear-gradient(103.99deg, #0294ff 55.01%, #0efed4 157.18%);
}
.white {
  color: #fff;
}

.founders-team-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.team-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.component-header {
  // font-size: 4rem;
  font-weight: 900;
  color: #1e1e1e;
  text-transform: uppercase;
  white-space: break-spaces;
}

.team-members {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-member {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 330px;
}

.member-photo {
  width: 100%;
  margin-bottom: 15px;
}

.member-info {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.member-name {
  font-size: 2rem;
  font-weight: 900;
  color: #1e1e1e;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.member-title {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.member-description {
  font-size: 14px;
  color: #777;
}

.achievements-section {
  margin-bottom: 40px;
}

.achievements-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.achievements-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
}

.achievement-item {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 8px 124px 0px #1e1e1e0a;
  gap: 12px;
}

.achievement-icon {
  width: 70px;
  height: 70px;
  // margin-bottom: 15px;
}

.achievement-info {
  display: flex;
  flex-direction: column;
}

.achievement-name {
  font-size: 18px;
  font-weight: bold;
}

.achievement-description {
  font-size: 14px;
  color: #777;
}

.roadmap-section {
  // text-align: center;
}

.roadmap-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.roadmap-list {
  margin-top: 20px;
}

.roadmap-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #1e1e1e;
}

.roadmap-quarter {
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 20px;
  text-transform: uppercase;
}

.roadmap-description {
  font-size: 16px;
  color: #000;
}

.testimonial-container {
  padding: 40px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0px 8px 124px 0px #1e1e1e0a;
}

.testimonial-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.testimonial-content {
  max-width: 800px;
  margin: 0 auto;
}

.testimonial-quote {
  position: relative;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 1.5;
  color: #555;
}

.quote-icon {
  font-size: 50px;
  color: #007bff; // Adjust to match your design color
  position: absolute;
  top: -20px;
  left: -10px;
}

.quote-text {
  font-size: 20px;
  font-weight: normal;
  color: #15171a;
  padding-left: 60px;
}

.testimonial-author {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.author-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.author-infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.author-name {
  font-size: 16px;
  font-weight: bold;
}

.author-title {
  font-size: 14px;
  color: #777;
}

.author-role {
  margin-left: auto;
  text-align: right;
}

.role-text {
  font-size: 14px;
  color: #15171a;
}

.get-in-touch-container {
  text-align: center;
}

.get-in-touch-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.contact-info {
  margin-bottom: 20px;
}

.contact-detail {
  margin-bottom: 10px;
}

.contact-label {
  font-size: 18px;
  color: #777;
}

.contact-value {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.social-icon {
  width: 40px;
  height: 40px;
  background-color: #333;
  border-radius: 50%;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.social-icon.twitter {
  background-color: #1e1e1e !important;
}
.social-icon.linkedin {
  background-color: #1e1e1e !important;
}
.social-icon.instagram {
  background-color: #1e1e1e !important;
}

.contact-message {
  color: #676767;
  font-size: 1.5rem;
  width: 70%;
  text-align: center;
}

.legal-compliance-container {
  text-align: center;
  border-radius: 12px;
}

// .legal-compliance-content {
//   background-color: #222;
//   color: #fff;
//   padding: 40px;
//   border-radius: 12px;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
// }

.section-title-legal {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: left;
}

.legal-compliance-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.details-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.details-list {
  list-style: none;
  padding: 0;
  text-align: left;
  color: #abaeb4;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.details-list li {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.full-deck-button {
  background-color: #333;
  color: #fff;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.full-deck-button:hover {
  background-color: #555;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.view-analytics-text {
  font-size: 14px;
  color: #777;
  margin-top: 10px;
}

.legal-compliance-container {
  border-radius: 12px;
}

.legal-compliance-content {
  background-color: #222;
  color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.slanted-square {
  width: 10px;
  height: 10px;
  background-color: #fff; /* Example color */
  transform: rotate(45deg); /* Rotates the square by 45 degrees */
  position: relative;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: left;
}

.legal-compliance-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-direction: column;
}

.details-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.details-list {
  list-style: none;
  padding: 0;
  text-align: left;
}

.details-list li {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.full-deck-button {
  background-color: #333;
  color: #fff;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.full-deck-button:hover {
  background-color: #555;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.view-analytics-text {
  font-size: 14px;
  color: #777;
  margin-top: 10px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 80px;
  background-color: #f9f9f9;
  color: #333;
}

.footer-left {
  flex: 1;
}

.brand-logo .brand-name {
  font-size: 24px;
  font-weight: bold;
  color: #007bff; // Adjust to match your brand color
}

.footer-tagline .tagline-text {
  font-size: 32px;
  font-weight: bold;
  margin: 20px 0;
}

.footer-rights {
  font-size: 14px;
  color: #777;
  margin-top: 20px;
}

.footer-right {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.footer-section {
  flex: 1;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-list li {
  font-size: 14px;
  margin-bottom: 10px;
  color: #555;
}

.footer-contact {
  font-size: 14px;
  margin-bottom: 10px;
  color: #555;
}

.footer-address {
  font-size: 14px;
  margin-bottom: 10px;
  color: #555;
}

.footer-social {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icon {
  width: 40px;
  height: 40px;
  background-color: #333;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.social-icon.facebook {
  background-color: #fff;
}
.social-icon.twitterblack {
  background-color: #fff;
}
.social-icon.linkedinblack {
  background-color: #fff;
}
.social-icon.instagramblack {
  background-color: #fff;
}

.header-ct-text {
  font-family: 'Test Founders Grotesk X-Condensed';
  src: url('../../../assets/fonts/test-founders-grotesk-x-condensed-bold.woff2');
}

.bgGr-scroll {
  background: linear-gradient(
    89.66deg,
    rgba(201, 214, 255, 0.32) -8.18%,
    rgba(226, 226, 226, 0.32) 108.89%
  );
}

.sec3Bg {
  background-image: url('../../../assets/images/nurwood/sec3Bg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .keyinsights span {
    font-size: 2rem !important;
  }
}
