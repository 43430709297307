body {
    margin: 0;
    font-family: 'Outfit', sans-serif;
    overflow-y: auto;
  }
  
  .app {
    background-color: white;
  }
  
  .app-header {
    background-image: url('../../assets/images/portfoliosbgn.png'),
    linear-gradient(96.63deg, #1E1E1E 1.22%, #1F1F1F 34.3%);  
    padding: 140px 20px 100px 120px;
    // height: 330px;
    display: flex;
    flex-direction: row;

    @media (min-width: 1101px) {
        position: relative;
    }
    @media (max-width: 1100px) {
        padding: 75px 35px;
        flex-direction: column;
    }
  }

  .app-header .star {
    height: 100%;

    @media (max-width: 1100px) {
        position: relative;
        height: 200px;
    }
  }

  .app-header .gradient-circles {
    position: absolute;
    right: 0;
    transform: translate(-50%, -50%);
    transform-origin: 0 0;
    opacity: 0.76;

    @media (max-width: 768px) {
        right: -60%;
        bottom: 40%;
    }
  }

  .app-header .gradient-circles .circle {
    width: 288.84px;
    height: 306.35px;
    position: absolute;
    transform: rotate(106deg);
    transform-origin: 0 0;
    background: linear-gradient(126.51deg, #87E2FF 18.9%, rgba(255, 255, 255, 0) 112.86%);

    box-shadow: 227.87px 227.87px 227.87px rgba(0, 0, 0, 0.1);
    border-radius: 9999px;
    filter: blur(120.87px);
  }
  
  .app-header h1 {
    display: flex;
    justify-content: center;
    font-size: 100px;
    margin: 0;
  }
  
  .app-header nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 1rem 0 0;
  }
  
  .app-header nav ul li {
    padding: 0.5rem 1rem;
    border-bottom: 2px solid transparent;
  }
  
  .app-header nav ul li a {
    text-decoration: none;
    color: #676767;
    font-weight: 400;
  }
  
  .app-header nav ul li a:hover,
  .app-header nav ul li a:focus {
    color: #131313;
  }
  
  .company-grid {
    display: grid;
  grid-template-columns: repeat(3, 1fr);
//   gap: 20px;
//   padding: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  }
  
  .company-card {
    background: #fff;
    border-bottom: 1px solid #e8e8e8 ;
    border-left: 1px solid #e8e8e8;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    padding: 80px 40px;
  }

  .company-card:nth-child(2) {
    padding-top: 0px;
  }
  
  .company-card:nth-last-child(-n + 3) {
    border-bottom: none;
  }
  
  
  .company-card-header {
    display: flex;
    align-items: start;
    // justify-content: space-between;
  }
  
  .company-card-number {
    width: 45px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
    font-size: 24px;
    color: #676767;
  }
  
  .company-card-title {
    font-size: 2rem;
    color: #676767;
    margin: 0;
  }
  
  .company-card-description {
    font-size: 1rem;
    color: #676767;
    margin: 10px 0;
  }
  
  .company-card-image {
    width: 399px;
    height: 399px;
    transition: transform 0.5s ease-in-out; 
  }

  .company-card:hover .company-card-image {
    transform: rotate(10deg);
    border-radius: 20%;
  }

  .company-card:hover .company-card-number {
    border: 2px solid;
    border-image-slice: 1;
    border-radius: 50%;
    border-image-source: linear-gradient(97deg, #0076D0 35%, #0EFED4 100%);
    color: #000;
  }

  .company-card:hover .company-card-title {
    color: #000;
  }

  .company-card:hover .company-card-description {
    color: #000;
  }
  
  .features-container {
    overflow-x: auto;
    // padding: 10px 0;
    margin-bottom: 25px;

    .features {
        display: flex;
        width: max-content;

        .feature {
            text-align: center;
            min-width: 180px;
            padding: 16px 12px;
            cursor: pointer;
        }
    }

    @media (max-width: 768px) {
        margin-bottom: 0px;
    }
}

body::-webkit-scrollbar {
  display: none;
  }

.table {
  width: 100%;
  border: 1px #E1E5E4 solid;
  border-collapse: collapse;
}

.table th {
  padding : 20px 0px;
  text-align: center;
  align-items: center;
}
.table td {
  padding: 12px; /* Adjust padding as needed */
  text-align: center;
}

.table th {
  color:#878787;
  font-size:18px;
  font-weight: 500;
  border: none;
  text-align: center;
  align-items: center;
  border-bottom: 1px #E1E5E4 solid;
}

tbody {
  tr {
    td {
      border: none; /* Remove border from table cells */
    }
  }
}


/* Adjust width and spacing between columns */  
.table .first-column {
    width: 80%;
}

.table th,
.table td:not(.first-column) {
  width: calc(100% / 3);
}

/* Alternative approach: Equal spacing with flexbox */
.table tr {
  display: flex;
  color:#FFFFFF;
  font-size:16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #F9FCFE;
  }
}



// .table th,
// .table td {
//   flex: 1;
// }

.investment-card-flex {
  display: flex;
  flex-wrap: wrap;
//   gap: 16px;
  border-top: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
}

.investment-card {
  background-color: #ffffff;
  padding: 32px;
  transition: all 0.3s ease;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 440px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  cursor: pointer;
  // height: 20%;

  @media (max-width: 768px) {
    padding: 32px 16px;
    width: 100%;
    flex: auto;
  }
}

.title {
  font-size: 18px;
  font-weight: bold;
  align-self: 'stretch';
  color: '#1E1E1E'; font-family: 'Outfit'; font-weight: '600'; line-height: '16px'; word-wrap: 'break-word';
}

.high-badge {
  background: rgba(78, 167, 55, 0.24);justify-content: center;align-items: center; gap: 4; display: 'inline-flex';
  // background-color: #28a745;
  color: white;
  padding: 6px 11px;
  border-radius: 12px;
  font-size: 12px;
}

.image-container {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;

  .image-container-img {
    width: 380px;
    height: 240px;

    @media (max-width: 768px) {
        height: 200px;
    }
  }
}

.description {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  padding-top: 16px;
  padding: 16px;
  height: 100px;

  @media (max-width: 768px) {
    border: none;
  }
}

.hover-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
//   border: 1px solid #0177D1;
  padding: 16px;
  background: #f9fcfe;
  // border: 1px solid 
  //   linear-gradient(90deg, #0176d0 50%, #0df0d4 50%) border-box;

    @media (max-width: 768px) {
        border: 1px solid #EFEFEF;
        background: white;
    }
}

.progress-circle {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: none;
  gap:12px;

  .circle {
    width: 40px;
    height: 40px;
    border: 3px solid #E1E5E4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #333;
  }

  .label {
    font-size: 12px;
    color: #666;
  }
}

.category-title {
    color: #1e1e1e;
    font-size: '16px';
    font-family: 'Outfit';
    font-weight: '600';
    line-height: '16px';
    word-wrap: 'break-word';
    text-transform: uppercase;
}

.investment-card:hover .category-title {
    color: #0177D1;
}




.border-style {
    width: 100%;
    @media (min-width: 768px) {
        // border: 1px solid transparent;
        background-image: linear-gradient(white, white), 
                          linear-gradient(to right, #0072ff, #00c6ff);
        background-origin: border-box;
        background-clip: content-box, border-box;
        text-align: center;
        display: inline-block;
        height: 100px;
        padding: 2px;
        height: 100%;
    }
}

.view-switch-icons {
    @media (max-width: 768px) {
        display: none !important;
    }
}

.body {
    padding: 80px 7%;
    @media (max-width: 768px) {
        padding: 0px;
    }
}

.title-wrapper {
    width: 50%;
    @media (max-width: 1100px) {
        width: 100%;
    }
}

.title-container {
    color: white;
    font-family: 'Test Founders Grotesk X-Condensed', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    // line-height: 72px;
    // word-wrap: break-word;
    margin-bottom: 35px;
    font-size: 72px;

    @media (max-width: 768px) {
        margin-top: 36px;
        font-size: 30px;
    }
}

.star-image {    
    position: absolute;
    right: -0%;
    bottom: -12%;
    transform: rotate(57deg);

    @media (min-width: 1350px) {
        height: 100%;
    }

    @media ((min-width: 1200px) and (max-width: 1350px)) {
        width: 600px;
    }

    @media ((min-width: 1100px) and (max-width: 1200px)) {
        width: 500px;
    }

    @media (max-width: 1100px) {
        width: 320px;
        right: 0%;
        bottom: -60%;
        height: auto;
    }
}

.investment-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.card-header-left {
    display: flex;
    flex-direction: column;
}