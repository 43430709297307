.doc-nav {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    padding: 16px 12px;
    cursor: pointer;
}

.doc-nav-active {
    background-color:  #E6F1FA;
}