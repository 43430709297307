.footer-animation {
    position: relative;
    height: 60px; /* Adjust based on your design */
    overflow: hidden;
  }
  
  .footer-animation .fade-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  
  .footer-animation .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  
  .footer-animation .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .footer-animation .fade-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 500ms, transform 500ms;
  }
  